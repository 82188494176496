import {
  createApiRef,
  DiscoveryApi,
  ErrorApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export interface HomeApi {
    getJiraTickets(): Promise<any>;
    getPullRequests(gitHubUsername): Promise<any>;
}

export const homeApiRef = createApiRef<HomeApi>({
    id: 'plugin.home.service',
});

type Options = {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
    errorApi: ErrorApi;
  };

export class DefaultHomeApi implements HomeApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly identityApi: IdentityApi;
    private readonly errorApi: ErrorApi;

    constructor(options: Options) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
        this.errorApi = options.errorApi;
    }

    private async internalCall<T = any>(
        input: string,
        requestOptions?: RequestInit,
    ): Promise<T> {
        const baseApiUrl = await this.discoveryApi.getBaseUrl('home');
    
        const { token } = await this.identityApi.getCredentials();

        const headers: HeadersInit = new Headers(requestOptions?.headers);

        if (token && !headers.has('authorization')) {
          headers.set('authorization', `Bearer ${token}`);
        }
    
        const request = new Request(`${baseApiUrl}${input}`, {
          ...requestOptions,
          headers,
        });
    
        return fetch(request).then(async response => {
          if (!response.ok) {
            throw await ResponseError.fromResponse(response);
          }
          return response.json() as Promise<T>;
        });
    }



    async getJiraTickets(): Promise<any> {
        const params = new URLSearchParams();
        
        const data = this.internalCall(`/jira/tickets`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        return data;
    }

    async getPullRequests(gitHubUsername: string): Promise<any> {
        const params = new URLSearchParams();
        params.append('gitHubUsername', gitHubUsername);

        const data = await this.internalCall(`/gh/pr?${params}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        return data;
    }
}