import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  errorApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { supportRotationsApiRef, DefaultSupportRotationsApi } from './api';
import { rootRouteRef } from './routes';

export const supportRotationsPlugin = createPlugin({
  id: 'support-rotations',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: supportRotationsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        errorApi: errorApiRef,
      },
      factory: ({ discoveryApi, identityApi, errorApi }) => {
        return new DefaultSupportRotationsApi({
          discoveryApi: discoveryApi,
          identityApi: identityApi,
          errorApi: errorApi,
        });
      },
    }),
  ],
});

export const SupportRotationsPage = supportRotationsPlugin.provide(
  createRoutableExtension({
    name: 'SupportRotationsPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
