import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef, errorApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { DefaultHomeApi, homeApiRef } from './api';

export const homePlugin = createPlugin({
  id: 'home',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: homeApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        errorApi: errorApiRef,
      },
      factory: ({ discoveryApi, identityApi, errorApi }) => {
        return new DefaultHomeApi({
          discoveryApi: discoveryApi,
          identityApi: identityApi,
          errorApi: errorApi,
        });
      },
    }),
  ],
});

export const HomePage = homePlugin.provide(
  createRoutableExtension({
    name: 'HomePage',
    component: () =>
      import('./components/HomeComponent').then(m => m.DevHomeComponent),
    mountPoint: rootRouteRef,
  }),
);