import {
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';

import bg from '../img/bg.png';

export const uprightLightTheme = createUnifiedTheme({
  palette: {
    ...palettes.light,
    primary: {
      main: '#333F4C',
    },
    secondary: {
      main: '#FF6A1F',
    },
    text: {
      primary: '#333F4C',
    },
    background: {
      default: '#D7F4F2',
    },
    navigation: {
      ...palettes.light.navigation,
      background: '#262638',
      color: '#FFFFFF',
      indicator: '#FF6A1F',
      navItem: {
        hoverBackground: 'rgba(116,118,121,0.6)',
      },
    },
  },
  fontFamily: 'Roboto, sans-serif',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          color: theme.palette.primary.main,
          boxShadow: 'unset',
          paddingBottom: theme.spacing(1),
          borderBottom: `1px solid #F2F4F7`,
          backgroundColor: '#FFFFFF',
          backgroundImage: `url(${bg})`,
          backgroundSize: '1200px',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
        }),
        title: ({ theme }) => ({
          color: theme.palette.primary.main,
          fontWeight: 900,
        }),
        subtitle: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          boxShadow: 'unset',
          paddingBottom: theme.spacing(1),
          color: theme.palette.primary.main,
        }),
        value: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    BackstageWarningPanel: {
      styleOverrides: {
        panel: ({ theme }) => ({
          boxShadow: 'unset',
          color: '#FFFFFF',
          backgroundColor: "#FF6A1F",
        }),
        message: ({ theme }) => ({
          boxShadow: 'unset',
          color: "#FFFFFF",
          backgroundColor: "#FF6A1F",
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 3,
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          borderBottom: '1px solid #CCCFD2',
        },
      },
    },
  }
});