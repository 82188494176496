import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const awsAppPlugin = createPlugin({
  id: 'platform',
  routes: {
    root: rootRouteRef,
  },
});

export const AwsAppPage = awsAppPlugin.provide(
  createRoutableExtension({
    name: 'AwsAppPage',
    component: () =>
      import('./components/AwsAppComponent').then(m => m.AwsAppComponent),
    mountPoint: rootRouteRef,
  }),
);
