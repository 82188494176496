import { DateTime } from 'luxon';
import { WebStorage } from '@backstage/core-app-api';
import {
  createApiRef,
  DiscoveryApi,
  ErrorApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import  axios  from 'axios';

const lastSeenKey = 'user_last_seen_date';

export type SupportRotation = {
  id: string;
  swe_engineer: string;
  data_engineer: string;
  start_date: string;
  updated_at: string;
};

export type SupportRotationsList = {
  count: number;
  results: SupportRotation[];
};

export interface SupportRotationsApi {
  supportRotations(opts: {
    max?: number;
    page?: number;
    starting_at?: string;
    ending_at?: string;
  }): Promise<SupportRotationsList>;

  supportRotationByID(id: string): Promise<SupportRotation>;

  upsertSupportRotation(
    request: SupportRotation,
  ): Promise<SupportRotation | undefined>;
  
  lastSeenDate(): DateTime;
  
  markLastSeenDate(date: DateTime): void;
  
  userList(): Promise<any>;
}

export const supportRotationsApiRef = createApiRef<SupportRotationsApi>({
  id: 'plugin.support-rotations.service',
});

type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  errorApi: ErrorApi;
};

export class DefaultSupportRotationsApi implements SupportRotationsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private readonly webStorage: WebStorage;

  constructor(opts: Options) {
    this.discoveryApi = opts.discoveryApi;
    this.identityApi = opts.identityApi;
    this.webStorage = new WebStorage('support-rotations', opts.errorApi);
  }

  private async fetch<T = any>(
    input: string,
    requestOptions?: RequestInit,
  ): Promise<T> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('support-rotations');

    const { token } = await this.identityApi.getCredentials();
    
    const headers: HeadersInit = new Headers(requestOptions?.headers);
    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const request = new Request(`${baseApiUrl}${input}`, {
      ...requestOptions,
      headers,
    });

    return fetch(request).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }
      return response.json() as Promise<T>;
    });
  }

  supportRotationByID(): Promise<SupportRotation> {
    throw new Error('Method not implemented.');
  }

  async supportRotations({
    max,
    page,
    starting_at,
    ending_at,
  }: {
    max?: number;
    page?: number;
    starting_at: string;
    ending_at: string;
  }): Promise<SupportRotationsList> {
    const params = new URLSearchParams();
    if (max) {
      params.append('max', max.toString());
    }
    if (page) {
      params.append('page', page.toString());
    }
    if (starting_at) {
      params.append('starting_at', starting_at);
    }
    if (ending_at) {
      params.append('ending_at', ending_at);
    }
    return this.fetch<SupportRotationsList>(
      `/support-rotations?${params.toString()}`,
    );
  }

  async upsertSupportRotation(
    request: SupportRotation,
  ): Promise<SupportRotation | undefined> {
    try {
      // Check if the rotation with the provided ID already exists
      const rotationsIsNew = request.id[0] === '-';
      if (rotationsIsNew) {
        const newRotation = await this.fetch<SupportRotation>(
          '/support-rotations/create',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
          },
        );
        return newRotation;
      }
      const updatedRotation = await this.fetch<SupportRotation>(
        `/support-rotations/edit`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request),
        },
      );
      return updatedRotation;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log('ERROR ', error);
      return undefined;
    }
  }

  lastSeenDate(): DateTime {
    const lastSeen = this.webStorage.get<string>(lastSeenKey);
    if (!lastSeen) {
      // magic default date, probably enough in the past to consider every supportRotation as "not seen"
      return DateTime.fromISO('1990-01-01');
    }

    return DateTime.fromISO(lastSeen);
  }

  markLastSeenDate(date: DateTime): void {
    this.webStorage.set<string>(lastSeenKey, date.toISO()!);
  }

  // TODO: create another factory in the plugin creationg
  async userList(): Promise<any> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('catalog');
    const { token } = await this.identityApi.getCredentials();
    return axios.get(`${baseApiUrl}/entities?filter=kind%3Duser`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }
}
