import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
<svg id="a" xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 150 158"><path d="m150,127.31H0v30.53h30.86c13.07,0,25.16-5.35,31.77-14.05l24.74-32.6c6.6-8.7,18.7-14.05,31.77-14.05h30.86v30.16Z" fill="#333f4c"/><path d="m150,78.82H0v30.53h30.86c13.07,0,25.16-5.35,31.77-14.05l24.74-32.6c6.6-8.7,18.7-14.05,31.77-14.05h30.86v30.16Z" fill="#333f4c"/><path d="m150,30.32H0v30.53h30.86c13.07,0,25.16-5.35,31.77-14.05l24.74-32.6C93.97,5.5,106.07.16,119.14.16h30.86v30.16Z" fill="#333f4c"/></svg>
  );
};

export default LogoIcon;
